import React from 'react'

import twitter from '../img/social/twitter.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div className="column">
              <div className="column social">
                <a title="twitter" href="https://twitter.com/schwalbe10">
                  <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
              </div>
              <p>&copy; 2019 哲学入門</p>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
